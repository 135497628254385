



























































import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import AddRxMedHeader from '@/components/medications/addRx/MedHeader.vue'
import LinkArrowIcon from '@/components/LinkArrowIcon.vue'
import Icon from '@/components/Icon.vue'
import Loading from '@/components/Loading.vue'
import PrescriptionStrength from '@/models/PrescriptionStrength'
import { arrivalMessage } from '@/i18n'
import { asapDeliveryDate, DAYS_TO_CHASE } from '@/util/addRx'
import Order from '@/models/Order'

@Component({
  components: {
    AddRxMedHeader,
    LinkArrowIcon,
    Icon,
    Loading,
  },
  computed: {
    ...mapGetters('addRx', ['medStrength']),
    ...mapGetters('orders', ['currentOrders', 'nextShipmentDate']),
    ...mapGetters('asyncStatus', ['isInProgress']),
  },
})
export default class Delivery extends Vue {
  medStrength!: PrescriptionStrength
  currentOrders!: Order[]
  nextShipmentDate!: Date | undefined

  selectNextShipment() {
    this.$store.commit('addRx/deliverInNextShipment')

    this.$router.push('/add-medication/review')
  }

  selectASAP() {
    this.$store.commit('addRx/deliverASAP')

    this.$router.push('/add-medication/review')
  }

  get nextShipmentDeliveryMessage() {
    return arrivalMessage(this.nextShipmentDate)
  }

  get asapDeliveryMessage() {
    const deliveryMessage = arrivalMessage(this.asapDeliveryDate)
    const chaseMessage = this.$t('if we received the prescription within {days} business days', {
      days: DAYS_TO_CHASE,
    })

    return this.$t('{deliveryMessage} {chaseMessage}', {
      deliveryMessage,
      chaseMessage,
    })
  }

  get fallbackDeliveryMessage() {
    return this.$t('addRx.fallbackDeliveryMessage')
  }

  get asapDeliveryDate() {
    return asapDeliveryDate()
  }

  async mounted() {
    await this.$store.dispatch('orders/loadCurrent')

    if (!this.nextShipmentDate) {
      this.$store.commit('addRx/deliverASAP')
    }
  }
}
